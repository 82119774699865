<template>
	<div class="cloudSearch">
		<div class="cloudSearch-top">
			<span class="index-title">媒体云搜索</span>
			<!-- <div class="cloudSearch-checkbox">
				<el-checkbox style="margin-right: 30px" v-model="checkAll"
					@change="handleCheckAllChange">全选</el-checkbox>
				<el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
					<el-checkbox v-for="city in cities" :label="city" :key="city.cloudId">
						{{ city.cloudName }}
					</el-checkbox>
				</el-checkbox-group>
			</div> -->
			<div class="cloudSearch-checkbox">
				<el-checkbox style="margin-right: 30px" v-model="checkAll" @change="checkAllchange">全选</el-checkbox>
				<el-checkbox-group v-model="checkSelect" @change="checkSelectChange">
					<el-checkbox v-for="(item,index) in searchWeb" :label="item" :key="index">
						{{ item.name }}
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div style="margin-top: 19px">
				<el-input  clearable v-model="input" placeholder="请输入内容" style="width: 1000px; margin-right: 24px"></el-input>
				<el-button type="primary" @click="cloudSearch">搜索</el-button>
			</div>
		</div>
		<div class="cloudSearch-cont">
			<div class="cloudSearch-container" v-show="!cloudSearchShow">
				<img src="../../assets/img/views/qita1.png" class="cloudSearch-container-img" />
			</div>
			<!-- <div v-for="(val, index) in listCheckedCities" :key="index" v-show="cloudSearchShow" class="cloudSearch-chard">
				<span class="cloudSearch-chard-title" @click="GO(val)">{{ val.cloudName }}结果</span>
				<span class="close" @click="clolsdData(val)">
					<img src="../../assets/img/views/cancel.png" alt />
				</span>
				<iframe :src="SearchText[val.cloudId - 1] + listKey" class="cloudSearch-chard-iframe"></iframe>
			</div> -->
			<div v-for="(val, index) in listCheckedCities" :key="index" v-show="cloudSearchShow" class="cloudSearch-chard" @mouseover="val.show=true" @mouseout="val.show=false">
				<span class="cloudSearch-chard-title" @click="GO(val)">{{ val.name }}结果</span>
				<span class="close" @click="clolsdData(val)">
					<img src="../../assets/img/views/cancel.png" alt />
				</span>
				<span class="enlarge el-icon-full-screen" @click="enlargeData(val)" v-show="val.show"></span>
				<iframe :src="val.address + listKey" class="cloudSearch-chard-iframe"></iframe>
			</div>
		</div>
		<div id="cloudSearch_enlarge" v-show="enlarge.show">
			<span class="cloudSearch-chard-title">{{enlarge.title}}结果</span>
			<span class="close" @click="closeEnlarge">
				<img src="../../assets/img/views/cancel.png" alt />
			</span>
			<iframe :src="enlarge.address+listKey" class="cloudSearch-chard-iframe"></iframe>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				checkAll: false,
				checkedCities: [],
				SearchText: [
					"https://www.sogou.com/web?query=",
					"https://cn.bing.com/search?q=",
					"https://www.sogou.com/sogou?insite=zhihu.com&query=",
					"https://weixin.sogou.com/weixin?query=",
				],
				cities: [],
				input: "",
				cloudSearchShow: false,
				listCheckedCities: [],
				listKey: "",
				searchWeb:[
					{name:"搜狗",address:"https://www.sogou.com/web?query=",show:false},
					{name:"必应",address:"https://cn.bing.com/search?q=",show:false},
					{name:"知乎",address:"https://www.sogou.com/sogou?insite=zhihu.com&query=",show:false},
					{name:"微信",address:"https://weixin.sogou.com/weixin?type=2&s_from=input&query=",show:false},
					{name:"360",address:"https://www.so.com/s?q=",show:false},
					// {name:"头条",address:"https://so.toutiao.com/search?dvpf=pc&source=input&keyword=",show:false},
					{name:"微博",address:"https://s.weibo.com/weibo?q=",show:false}
				],
				checkSelect:[],
				enlarge:{
					show:false,
					address:"https://www.sogou.com/",
					title:""
				}
			};
		},
		created() {
			// this.getSearch();
			this.checkAll = true;
			this.checkAllchange(this.checkAll);
		},
		methods: {
			// getSearch() {
			// 	this.$http({
			// 			method: "post",
			// 			url: "/api/system/cloudSearch/searchList?pageNum=1&pageSize=30",
			// 			data: {},
			// 		})
			// 		.then((res) => {
			// 			if (res.data.code == 200) {
			// 				res.data.rows.forEach(x => {
			// 					if (x.state == "0") {
			// 						this.cities.push(x);
			// 						this.checkedCities.push(x)
			// 					}
			// 				})
			// 			}
			// 			this.checkAll = true;
			// 		})
			// 		.catch((err) => {
			// 			console.log(err);
			// 		});
			// },
			// 关闭
			clolsdData(val) {
				this.listCheckedCities.forEach((item, index) => {
					if (item.name == val.name) {
						this.listCheckedCities.splice(index, 1);
					}
				});
				if (this.checkSelect.length != this.searchWeb.length) {
					this.checkAll = false;
				}
			},
			//全屏
			enlargeData(val){
				this.enlarge.address=val.address;
				this.enlarge.title=val.name;
				this.enlarge.show=true;
			},
			//关闭全屏
			closeEnlarge(){
				this.enlarge.show=false;
			},
			// //全选
			// handleCheckAllChange(val) {
			// 	let cities = this.cities.concat();
			// 	this.checkedCities = val ? cities : [];
			// },
			// //单选
			// handleCheckedCitiesChange(value) {
			// 	let checkedCount = value.length;
			// 	this.checkAll = checkedCount === this.cities.length;
			// 	console.log(this.checkedCities)
			// },
			checkAllchange(val){
				let arr = this.searchWeb.concat([]);
				this.checkSelect = val ? arr : [];
			},
			checkSelectChange(val){
				let length = val.length;
				this.checkAll = length === this.searchWeb.length;
			},
			//搜索
			cloudSearch() {
				if (this.checkSelect.length == 0) {
					this.$message("请选择搜索器！");
					return false;
				}
				if (this.input.trim() == "") {
					this.$message("请输入内容！");
					this.input = "";
					return false;
				}
				this.listCheckedCities = JSON.parse(JSON.stringify(this.checkSelect));
				this.listKey = this.input;
				this.cloudSearchShow = true;
			},
			//去搜索页
			// GO(val) {
			// 	window.open(this.SearchText[val.cloudId - 1] + this.input, "_blank");
			// },
			GO(val) {
				window.open(val.address + this.input, "_blank");
			},
		},
	};
</script>

<style scoped>
	.cloudSearch-top {
		width: 100%;
		height: 200px;
		background: #ffffff;
		border-radius: 4px;
		padding: 22px 24px;
	}

	.cloudSearch-checkbox {
		margin-top: 34px;
		display: flex;
	}

	.cloudSearch-cont {
		width: 100%;
		padding: 24px;
		display: flex;
		flex-wrap: wrap;
	}

	.cloudSearch-container {
		width: 100%;
		height: 617px;
		background: #ffffff;
		border-radius: 8px;
		text-align: center;
	}

	.cloudSearch-container-img {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	.cloudSearch-chard {
		position: relative;
		width: 48%;
		height: 665px;
		background: #ffffff;
		border-radius: 8px;
		padding: 24px;
		float: left;
		margin-right: 24px;
		margin-top: 24px;
	}

	.cloudSearch-chard-title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		cursor: pointer;
	}

	.cloudSearch-chard-iframe {
		width: 100%;
		height: 100%;
	}

	.close {
		cursor: pointer;
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.enlarge{
		cursor: pointer;
		position: absolute;
		bottom: 16px;
		right: 42px;
		z-index: 20;
		padding: 2px;
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.15);
	}

	#cloudSearch_enlarge{
		width: 1200px;
		height: 800px;
		padding: 24px;
		display: flex;
		flex-wrap: wrap;
		position: fixed;
		left: 0;
		right: 0;
		top: 100px;
		margin: auto;
		background-color: #fff;
		z-index: 20;
		border-radius: 5px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
	}
</style>
